.organization-settings-container {
    display: flex;
}

.sidebar {
    width: 200px;
    background-color: #f8f9fa;
    padding: 1rem;
    border-right: 1px solid #ddd;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    padding: 0.5rem;
    cursor: pointer;
    color: #333;
}

.sidebar li.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
}

.content {
    flex: 1;
    padding: 1rem;
}
